@if(isContentLoaded()) {
@if (membershipList().length > 1) {
<b class="">What kind of delivery are you interested in?</b>
<mat-radio-group class="row mb-4" ngDefaultControl [(ngModel)]="selectedMembership().id"
  (ngModelChange)="setSelectedMembership($event)">
  @for (delivery of membershipList(); track $index) {
  <div class="col-sm-4">
    <mat-radio-button [checked]="delivery.id === selectedMembership().id" [value]="delivery.id">
      {{delivery.name}}
    </mat-radio-button>
  </div>
  }
</mat-radio-group>
}
@if (selectedMembership().id != 0) {
@if (selectedMembership().configuration?.requireAddress) {
<div class="form-floating">
  <input #autocompleteInput (input)="changeAddress()" type="text" name="address" [disabled]="isCheckingAddress()"
    [class.is-invalid]="!isLoadingContent() && (!hasDeliveryAddressCoverage() && isGoogleAddressFilled())"
    [class.is-valid]="!isLoadingContent() && hasDeliveryAddressCoverage()" class="form-control" id="address" placeholder
    [(ngModel)]="queryString">
  <label for="address">Please enter your address</label>
  @if (isLoadingContent()) {
  <mat-progress-bar mode="indeterminate" value="accent"></mat-progress-bar>
  } @else {
  @if (hasDeliveryAddressCoverage()) {
  <div class="valid-tooltip right-0">
    This address is in our delivery area!
  </div>
  } @else if (isGoogleAddressFilled()) {
  <div class="invalid-tooltip right-0">
    Oops! Your address isn't in our delivery zone.
  </div>
  }
  }
</div>
} @else {
@if (pickUpOptions().length > 1) {
<div class="mt-5 animated fadeInDown mb-5">
  <mat-form-field class="w-100">
    <mat-label>Pickup Address</mat-label>
    <mat-select name="selectedPickUpOption" [value]="selectedPickUpOption().id || null"
      (selectionChange)="setSelectedPickUpOption($event)">
      @for (item of pickUpOptions(); track $index) {
      <mat-option [value]="item.id">{{ item.composedName }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
} @else {
<div class="form-floating">
  <input type="text" class="form-control" id="pickupLocation" name="pickUpLocation" readonly
    [value]="selectedPickUpOption().composedName || ''" placeholder>
  <label for="address">Pickup address</label>
</div>
}
}
}


<div class="d-grid gap-2 col-md-6 mx-auto mt-5">
  @if(selectedMembership().configuration?.requireAddress) {
  <button type="button" class="btn btn-primary" [disabled]="isCheckingAddress()"
    (click)="checkDeliveryAddressCoverage()">Check address</button>
  } @else {
  <button [disabled]="!selectedPickUpOption().id" type="button" class="btn btn-primary" (click)="goToSignup()">Continue
    To Signup</button>
  }
  <small class="text-center mt-4">Already have an account? <a class="text-black fw-bold" [routerLink]="['/login']"
      (click)="closeModal()">Log in</a>
    @if (selectedMembership().configuration?.requireAddress) {
    or <a class="text-black fw-bold" [routerLink]="['/signup']" (click)="closeModal()">Sign up</a>
    }
  </small>
</div>

}
