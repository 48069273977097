import { Injectable, WritableSignal, signal } from "@angular/core";
import { Sidebar, SidebarCheckList, SidebarFilterActions, SidebarFilterTypes } from './sidebar.types';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {


  sidebar: WritableSignal<SidebarCheckList[]> = signal([]);
  #sidebarPersistency: WritableSignal<SidebarCheckList[]> = signal([]);



  #categoryToNavigate: BehaviorSubject<SidebarCheckList> = new BehaviorSubject({} as SidebarCheckList);
  categoryToNavigate$ = this.#categoryToNavigate.asObservable();

  private filterTypes: any = {
    [SidebarFilterTypes.categories]: { property: 'category', isArray: false },
    [SidebarFilterTypes.subcategories]: { property: 'subcategory', isArray: false },
    [SidebarFilterTypes.producers]: { property: 'producer', isArray: false },
    [SidebarFilterTypes.dietRestrictions]: { property: 'dietRestrictions', isArray: true },
    [SidebarFilterTypes.tags]: { property: 'tags', isArray: true },
  }

  setUpSidebarData(
    data: {
      sidebarData: Sidebar | any,
      filterType?: SidebarFilterActions,
      isFirstTime?: boolean
    }
  ) {
    const { sidebarData, filterType, isFirstTime } = data;

    if (filterType === SidebarFilterActions.isFiltering)
      return this.sidebar.update(() => [...this.#setUpFilteredSidebar(sidebarData)]);

    if (filterType === SidebarFilterActions.isClearing)
      return this.sidebar.update(() => [...this.#sidebarPersistency()]);

    this.sidebar.update(() => [...this.#setUpInitialSidebar(sidebarData, isFirstTime)]);

  }

  #setUpInitialSidebar(sidebarData: any, isFirstTime?: boolean): SidebarCheckList[] {
    const res: SidebarCheckList[] = []
    for (const cat of sidebarData?.categories ?? []) {
      res.push(this.#setUpSidebarChecklistArray(cat, cat.subCategories));
    }

    if (isFirstTime)
      this.#sidebarPersistency.update(() => [...res]);

    return res;
  }

  #setUpFilteredSidebar(sidebarData: Sidebar): SidebarCheckList[] {
    const currentSidebar = this.#sidebarPersistency();
    if (currentSidebar.length) {
      const sidebarCategories: number[] = sidebarData?.categories?.map((c: { id: string | number; }) => +c.id) || [];
      const sidebarSubcategories: number[] = sidebarData?.subcategories?.map((s: { id: string | number; }) => +s.id) || [];
      const sidebarSubcategoriesSons: number[] = sidebarData?.subcategoriesSons?.map((ss: { id: string | number; }) => +ss.id) || [];
      const menuFilteredData = currentSidebar.filter(c => sidebarCategories.includes(+c.id)).map(c => {
        const menuSubcategoriesFilteredData = (c.sublist?.filter((s: { id: string | number; }) => sidebarSubcategories.includes(+s.id)) || []).map(ss => {
          return {
            ...ss,
            sublist: ss.sublist?.filter(aux => sidebarSubcategoriesSons.includes(+aux.id)) || []
          }
        });
        return this.#setUpSidebarChecklistArray(c, menuSubcategoriesFilteredData, true)
      })

      if (menuFilteredData.length) {
        menuFilteredData[0].checked = true;
        if (menuFilteredData[0].sublist?.length)
          menuFilteredData[0].sublist[0].checked = true;
      }

      return menuFilteredData || [];
    }

    return [];
  }

  #setUpSidebarChecklistArray(c: any, sublist: any[], filtering: boolean = false) {
    return {
      id: c.id,
      name: c.name.trim(),
      checked: false,
      key: c.id,
      sublist: sublist?.map((s) => {
        const sublistson = filtering ? s.sublist : s.subCategories;

        s.name = s.name.trim();
        s.checked = false;
        s.key = `${c.id}-${s.id}`;
        s.sublist = sublistson?.map((sub_s: any) => {
          sub_s.name = sub_s.name.trim();
          sub_s.checked = false;
          sub_s.key = `${c.id}-${s.id}-${sub_s.id}`;
          return sub_s;
        }) || []
        return s;
      }) || []
    } as SidebarCheckList
  }

  setUpSidebarDataAfterFilter(products: any) {
    this.sidebar().forEach(filter => {
      const filterType = this.filterTypes[filter.id];

      if (filterType) {
        this.matchFilter(filter, products, filterType.property, filterType.isArray)
      }
    });
  }

  private matchFilter(filter: SidebarCheckList, products: any, type: string, isArray: boolean) {
    filter.sublist?.map(item => {
      products.some((product: any) => {
        const match = isArray ? product?.[type]?.map((t: any) => t.id).includes(item.id) : product[type]?.id === item.id;
        item.disabled = !match;
        return match
      });
    });
  }

  goToCategory(item: SidebarCheckList) {
    if (!item) return;
    this.#categoryToNavigate.next(item);
  }



}
