import { WritableSignal } from "@angular/core";
import { FirebaseOrder } from "../signals-store.service";
import { Item, MarketStatus } from "./common.types";
import { Bundle, Product, ProductPresentation, Tag } from "../../product/product.types";

export enum RELATED_ORDER_PRODUCTS {
  FAVORITES = 'favorites',
  BUY_AGAIN = 'buyAgain',
  SUGGESTED = 'suggested',
}

export interface Category extends Item {
  minSpend?: number;
}

export interface Coupon extends Item {
  code: string;
  amount: number;
  benefit: string;
  discount: string;
  isApplied: boolean;
}

export interface DeliveryFee {
  amount: number;
  minSpend: number;
  taxes: number;
}

export interface Description {
  large: string;
  short: string;
}

export interface OrderResponse extends Item {
  paymentDetails: PaymentDetails;
  products: {
    common: ProductOrder[];
    subscription: ProductOrder[];
  };
  status: Status;
  relatedProducts: {
    suggested: Partial<Product>[];
    favorites: Partial<Product>[];
    buyAgain: Partial<Product>[];
  },
  isSkipped: boolean;
  invoiceDetails: any;
  outOfStockProducts?: any[];
  marketStatus: MarketStatus;
  cutOff: number;
}

export interface PaymentDetails {
  credits: number;
  deposits: number;
  seasonalDeposits?: number;
  deliveryFee: DeliveryFee;
  minOrder: number;
  coupons: Coupon[];
  tip: Tip;
  donation: Tip;
  subTotal: number;
  taxes: number;
  total: number;
  fees: Fee[]
}

export interface Fee {
  name: string;
  percentage?: number;
  value?: number;
  // total?: WritableSignal<number>;
  total?: number;
}

export interface Producer {
  name: string;
}

export interface PublishedBundles {
  id: number;
  dateFrom: string;
  dateTo: string;
  isFixed: boolean;
}

export interface ProductOrder {
  attributes: any;
  bundle?: Bundle;
  category: Category;
  deposit: number;
  id: number;
  img: string;
  isSubscription: boolean;
  isASubscription?: boolean;
  name: string;
  presentation: ProductPresentation;
  price: number;
  originalPrice?: number;
  seasonalDeposit?: number;
  quantity: number;
  subCategory: Category;
  taxes: Tax[];
  totalPrice?: string | number;
  isBundle: boolean;
  hrImg?: string;
  isALaCarte: boolean;
  lineId?: number;
  variantId: number;
  variant: Variant,
  package: any;
  productUrl: string;
  isPreOrder: boolean;
  hasDiscount: boolean;
  subscription: Subscription
}

export interface Tax extends Item {
  percentage: number;
}

export interface Variant {
  id: number;
  attribute: any;
  variantId: number
}

export interface Subscription {
  id: number;
  frequency: Item;
  startDate: string;
}


export interface FireBaseProductOrder {
  bundle?: Bundle;
  category: Category;
  deposit: number;
  id: number;
  img: string;
  isSubscription: boolean;
  isASubscription?: boolean;
  name: string;
  package: {
    id: number;
    name: string;
    quantity: number;
  };
  presentation: ProductPresentation;
  price: number;
  productUrl: string;
  quantity: number;
  subCategory: Category;
  subscription: {
    frequency: {
      id: number,
      name: string
    },
    startDate: string | null,
  } | null,
  tax: number;
  totalPrice?: string | number;
  variant: {
    attribute: {
      id: number,
      name: string,
      value: Item
    },
    id: number,
    productId: number,
    stock: number,
  }
  deliveryDates?: string[],
  bundleQuantityChanged?: boolean;
  isPublishedBundle?: boolean;
}

export interface Status {
  deliveryDate: string;
  id: number;
  isInProgress: boolean;
  isOnWay: boolean;
  isSaved?: boolean;
}

export interface Tip {
  amount: number;
  isRecurrent: boolean;
}

export interface TipsAndDonationsPayload {
  amount: number;
  isRecurrent: boolean;
  applyToAllOrders?: boolean;
}

export enum BundleEditionTypes {
  subscription = 'subscription',
  aLaCarte = 'common'
}

export type BundleEditionType = BundleEditionTypes.aLaCarte | BundleEditionTypes.subscription;

export type UpdateOrderParams = {
  order: FirebaseOrder;
  firebaseOrderSignal?: WritableSignal<any>;
  orderId?: number | string;
  getStock?: boolean;
  showDefaultMessage?: boolean;
  coupon?: WritableSignal<string>;
  deliveryInfo?: { deliveryDateText: string, cutoffDateText: string, thanksMessage: string };
  isUpdatingBundle?: boolean;
};

export type ShopBundleList = {
  frequency: string;
  name: string;
  id: number;
  image: string;
  price: number;
}
