<div class="modal-header border-0">
  @if (modalContentData.closeable) {
  <button type="button" class="btn-close" aria-label="Close" (click)="crossClickHandle()"></button>
  }
</div>
<div class="modal-body">
  @if (modalContentData) {
  <div class="d-grid gap-2 col-lg-12 col-md-12 mx-auto">
    <div class="col-md-auto">
      <h2 class="modal-title text-center animated fadeInDown head-font text-secondary-800 h2-bold">
        {{ modalContentData.title }}
      </h2>
      <div class="mobile-ios-top-pad"></div>
      @if (modalContentData.textContent) {
      <div class="text-center mt-2 h6-regular animated fadeIn mb-5 text-secondary-700" #textContent></div>
      }
    </div>
  </div>
  }

  @switch (modalContentType) {
  @case (modalContentTypes.CHECK_ADDRESS) {
  <app-check-address></app-check-address>
  <!-- <app-check-address-signup></app-check-address-signup> -->
  }
  @case (modalContentTypes.HOW_IT_WORKS) {
  <app-how-it-works [modalContentData]="modalContentData"></app-how-it-works>
  }

  @case (modalContentTypes.WELCOME_MESSAGE) {
  <app-welcome-message [modalContentData]="modalContentData"></app-welcome-message>
  }

  @case (modalContentTypes.SEND_INFORMATION) {
  <app-send-information [modalContentData]="modalContentData"></app-send-information>
  }

  @case (modalContentTypes.BOX_DETAIL) {
  <app-modal-box-detail [boxDetail]="modalContentData.boxDetail || null"></app-modal-box-detail>
  }
  @case (modalContentTypes.PAUSE_SUBSCRIPTION) {
  <app-pause-subscriptions [data]="modalContentData"></app-pause-subscriptions>
  }
  @case (modalContentTypes.CONFIRMATION) {
  <div class="d-flex justify-content-center gap-4 animated fadeInUp" [class.mt-5]="!modalContentData.textContent">
    @if (modalContentData.cancelButtonText) {
    <button type="button" class="btn btn-outline-secondary head-font h6-regular"
      (click)="confirmModalButtonsAction(false)">{{modalContentData.cancelButtonText}}</button>
    }
    @if (modalContentData.confirmButtonText) {
    <button type="button" class="btn btn-primary head-font h6-regular"
      (click)="confirmModalButtonsAction(true)">{{modalContentData.confirmButtonText}}</button>
    }
  </div>
  }
  @case (modalContentTypes.SKIP_DELIVERY) {
  <app-skip-delivery></app-skip-delivery>
  }
  @case (modalContentTypes.BECOME_A_MEMBER) {
  <app-become-a-member [data]="modalContentData.becomeAMemeber"></app-become-a-member>
  }
  @case (modalContentTypes.BUY_AGAIN) {
  <app-buy-again-order-history
    [orderHistoryId]="modalContentData.buyAgain?.orderHistoryId"></app-buy-again-order-history>
  }
  @case (modalContentTypes.CHANGE_CREDENTIALS) {
  <app-change-credentials [email]="modalContentData.changeCredentials?.email || ''"></app-change-credentials>
  }

  @case (modalContentTypes.PROSPECT) {
  <app-prospect></app-prospect>
  }

  @case (modalContentTypes.PENDING_PAYMENT_METHOD) {
  <app-pending-payment-method></app-pending-payment-method>
  }

  @case (modalContentTypes.PENDING_PAYMENT_METHOD_NO_SIGNUP) {
  <app-pending-payment-method-no-signup></app-pending-payment-method-no-signup>
  }

  @case (modalContentTypes.MEMBERSHIP_CSA_DEPOSITS) {
  <app-membership-csa-deposit></app-membership-csa-deposit>
  }

  @case (modalContentTypes.DONATION_SKIPPABLE) {
  <app-donation-skippable></app-donation-skippable>
  }

  @case (modalContentTypes.DONATION) {
  <app-donation></app-donation>
  }

  @case (modalContentTypes.TIP) {
  <app-tip></app-tip>
  }

  @case (modalContentTypes.DELETE_TIP_AND_DONATION) {
  <app-delete-tip-donation></app-delete-tip-donation>
  }

  @case (modalContentTypes.HOLD_UP_UPDATE_SUBSCRIPTION) {
  <app-hold-up-subscriptions></app-hold-up-subscriptions>
  }

  @case (modalContentTypes.BUY_AGAIN_WARNING) {
  <app-buy-again-order-history-warning
    [products]="modalContentData.orderHistoryWarning || {products: [], showThirdColumn: true, fromOrder: false}"></app-buy-again-order-history-warning>
  }

  @case (modalContentTypes.PAST_CUT_OFF) {
  <app-past-cut-off></app-past-cut-off>
  }

  @case (modalContentTypes.CHECK_SUBMIT_ORDER) {
  <app-check-submit-order></app-check-submit-order>
  }

  @case (modalContentTypes.ACCOUNT_SUSPENDED) {
  <app-suspended-account></app-suspended-account>
  }

  @case (modalContentTypes.SELECT_DELIVERY_DAY) {
  <app-delivery-day-selection
    [zoneId]="modalContentData.deliveryDaySelection?.zoneId || 0"></app-delivery-day-selection>
  }

  @case (modalContentTypes.SUBSCRIPTION_NEXT_DELIVERY) {
  <app-subscription-next-delivery
    [availableDates]="modalContentData.subscriptionNextDelivery?.availableDates || []"></app-subscription-next-delivery>
  }

  @case (modalContentTypes.PREORDER_PRODUCT) {
  <app-pre-order-product [data]="modalContentData.preOrderProduct"></app-pre-order-product>
  }

  @case (modalContentTypes.RESCHEDULED_ORDER) {
  <app-rescheduled-orders [orders]="modalContentData.rescheduledOrders"></app-rescheduled-orders>
  }

  @case (modalContentTypes.RESUME_SUBSCRIPTION) {
  <app-resume-subscriptions [data]="modalContentData.resumeSubscriptions"></app-resume-subscriptions>
  }

  @case (modalContentTypes.AUTH_NET_PURCHASE) {
  <app-auth-net-purchase [purchaseReqAddFields]="modalContentData.authNetPurchase"></app-auth-net-purchase>
  }

  @case (modalContentTypes.NMI_PURCHASE) {
  <app-nmi-purchase [purchaseReqAddFields]="modalContentData.nmiPurchase"></app-nmi-purchase>
  }

  }
</div>