import { Component, Input, inject, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentData } from './modal-content';
import { ModalContentTypes } from '../constants/modal-content-types';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { provideNativeDateAdapter } from '@angular/material/core';
import { ModalContentService } from './modal-content.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChangeCredentialsComponent } from './change-credentials/change-credentials.component';
import { ProspectComponent } from '../modal/contents/prospect.component';
import { PendingPaymentMethodComponent } from "../modal/contents/pending-payment-method.component";
import { PauseSubscriptionsComponent } from './pause-subscriptions/pause-subscriptions.component';
import { MembershipCsaDepositComponent } from './membership-csa-deposit/membership-csa-deposit.component';
import { BecomeAMemberComponent } from './become-a-member/become-a-member.component';
import { CheckAddressComponent } from './check-address/check-address.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { DonationComponent } from './donation/donation.component';
import { DonationSkippableComponent } from './donation-skippable/donation-skippable.component';
import { TipComponent } from './tip/tip.component';
import { BuyAgainOrderHistoryComponent } from './buy-again-order-history/buy-again-order-history.component';
import { SkipDeliveryComponent } from './skip-delivery/skip-delivery.component';
import { HoldUpSubscriptionsComponent } from './hold-up-subscriptions/hold-up-subscriptions.component';
import { CardUpdateInProgressComponent } from './card-update-in-progress/card-update-in-progress.component';
import { BuyAgainOrderHistoryWarningComponent } from './buy-again-order-history-warning/buy-again-order-history-warning.component';
import { PastCutOffComponent } from "./past-cut-off/past-cut-off.component";
import { CheckSubmitOrderComponent } from './check-submit-order/check-submit-order.component';
import { SuspendedAccountComponent } from './suspended-account/suspended-account.component';
import { Router, RouterLink } from '@angular/router';
import { DeleteTipDonationComponent } from './delete-tip-donation/delete-tip-donation.component';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';
import { DeliveryDaySelectionComponent } from './delivery-day-selection/delivery-day-selection.component';
import { SendInformationComponent } from './send-information/send-information.component';
import { PendingPaymentMethodNoSignupComponent } from "./pending-payment-method-no-signup/pending-payment-method-no-signup.component";
import { SubscriptionNextDeliveryComponent } from './subscription-next-delivery/subscription-next-delivery.component';
import { PreOrderProductComponent } from './pre-order-product/pre-order-product.component';
import { RescheduledOrdersComponent } from './rescheduled-orders/rescheduled-orders.component';
import { ResumeSubscriptionsComponent } from './resume-subscriptions/resume-subscriptions.component';
import { AuthNetPurchaseComponent } from './auth-net-purchase/auth-net-purchase.component';
import { NmiPurchaseComponent } from './nmi-purchase/nmi-purchase.component';
import { ModalBoxDetailComponent } from './modal-box-detail/modal-box-detail.component';
@Component({
    selector: 'app-modal-content',
    providers: [provideNativeDateAdapter()],
    templateUrl: './modal-content.component.html',
    styleUrl: './modal-content.component.scss',
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        ChangeCredentialsComponent,
        ProspectComponent,
        PendingPaymentMethodComponent,
        PauseSubscriptionsComponent,
        MembershipCsaDepositComponent,
        BecomeAMemberComponent,
        CheckAddressComponent,
        HowItWorksComponent,
        WelcomeMessageComponent,
        DonationComponent,
        DeleteTipDonationComponent,
        DonationSkippableComponent,
        TipComponent,
        BuyAgainOrderHistoryComponent,
        SkipDeliveryComponent,
        HoldUpSubscriptionsComponent,
        BuyAgainOrderHistoryWarningComponent,
        PastCutOffComponent,
        CheckSubmitOrderComponent,
        SuspendedAccountComponent,
        DeliveryDaySelectionComponent,
        SendInformationComponent,
        PendingPaymentMethodNoSignupComponent,
        SubscriptionNextDeliveryComponent,
        PreOrderProductComponent,
        RescheduledOrdersComponent,
        ResumeSubscriptionsComponent,
        AuthNetPurchaseComponent,
        NmiPurchaseComponent,
        ModalBoxDetailComponent
    ]
})
export class ModalContentComponent implements AfterViewInit {

  modalService = inject(ModalContentService);
  activeModal = inject(NgbActiveModal);
  private renderer2 = inject(Renderer2);
  private router = inject(Router);

  @ViewChild('textContent') textContent!: ElementRef;

  modalContentTypes = ModalContentTypes;
  @Input() modalContentType: ModalContentTypes = ModalContentTypes.CHECK_ADDRESS;

  modalContentData!: ModalContentData

  isDonationCanceled: boolean = true;

  ngAfterViewInit(): void {
    this.setUpDynamicTextContentLinks();
  }

  private setUpDynamicTextContentLinks() {
    if (!this.modalContentData.textContent) return;

    const container = this.textContent.nativeElement;

    // Check if the dynamic content contains an <a> tag
    if (!this.modalContentData.textContent.includes('<a')) {
      container.innerHTML = this.modalContentData.textContent;
      return;
    }

    const tempElement = this.renderer2.createElement('div');
    tempElement.innerHTML = this.modalContentData.textContent;

    Array.from(tempElement.childNodes).forEach((node: any) => {
      if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName === 'A') {
        const linkElement = node as HTMLElement;
        const routerLink = linkElement.getAttribute('routerLink');
        if (routerLink) {
          this.renderer2.listen(linkElement, 'click', (event) => {
            event.preventDefault();
            this.router.navigate([routerLink]);
            this.closeModal(null);
          });
        }
      }
      this.renderer2.appendChild(container, node);
    });
  }

  confirmModalButtonsAction(confirm: boolean) {
    this.activeModal.close({ confirm });
  }

  crossClickHandle() {
    this.activeModal.close({
      isDonationCanceled: this.isDonationCanceled
    });
  }

  closeModal(sendData: any) {
    this.activeModal.close(sendData);
  }

}
