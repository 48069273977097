import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { map, tap } from 'rxjs';
import { ApiService } from '../shared/api.service';
import { RequestHandlerParams } from '../shared/types/api-service.types';
import { ApiResponse } from '../shared/common/types';
import { arrayToMapMultiKey } from '../shared/common/utils';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  private apiService = inject(ApiService);
  private endpoints = {
    stock: `/stock`,
  }

  isLoading: WritableSignal<boolean> = signal(false);
  isLoaded: WritableSignal<boolean> = signal(false);
  stockSignal: WritableSignal<any[]> = signal([]);

  constructor() { }

  getStock(categoryId?: number, productsId?: number[], getAll: boolean = false) {
    if (!categoryId && !productsId?.length && !getAll) return;
    this.stockSignal.set([]);
    this.isLoading.set(true);

    const query = this.setUpStockParams(categoryId, productsId, getAll);
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.stock}${query}`,
      method: 'GET',
      apiV3: true,
      showErrorMessage: false
    }

    this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: any) => {
        if (!response || !response.data)
          throw new Error('Stock Error');
        this.stockSignal.set(response.data);
        this.isLoading.set(false);
        this.isLoaded.set(true);
      }),
    ).subscribe();
  };

  private setUpStockParams(categoryId?: number, productsId?: number[], getAll: boolean = false): string {
    if (getAll) return `?getAll=1`;
    const params: string[] = [];

    if (categoryId) params.push(`categoryId=${categoryId}`);
    if (productsId?.length) {
      const productsIdEnconded = encodeURIComponent(productsId.join(';'))
      params.push(`productId=${productsIdEnconded}`);
    }

    return params.length > 0 ? '?' + params.join('&') : '';
  }

  getStandAloneStockByProductsId(productsId: number[]) {
    const query = this.setUpStockParams(undefined, productsId);
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.stock}${query}`,
      method: 'GET',
      apiV3: true,
      showErrorMessage: false
    }

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      map(res => res.data)
    );
  }
}
