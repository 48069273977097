import { Component, EventEmitter, Input, NgZone, OnInit, Output, Signal, WritableSignal, computed, inject, signal, Inject, PLATFORM_ID } from '@angular/core';
import { DeliveryAddress } from '../signup.types';
import { AddressService, NavegoAddressRequest } from '../../../shared/address.service';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatStep } from '@angular/material/stepper';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterLink } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { ArrayOptionsItem, DeliveryDay, DeliveryOptionsResponseItem, PickUpOption } from '../../../shared/types/account.types';
import { AccountService } from '../../../shared/account.service';
import { AutocompleteInputComponent } from '../autocomplete-input/autocomplete-input.component';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { LOCALSTORAGE_KEYS } from '../../../shared/constants/databases';
import { tap } from 'rxjs';
import { formatDateToReadableString } from '../../../shared/utils/formatting';

@Component({
    selector: 'app-check-address-signup',
    providers: [],
    imports: [
        CommonModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIcon,
        MatSelectModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        RouterLink,
        MatRadioModule,
        AutocompleteInputComponent,
    ],
    templateUrl: './check-address-signup.component.html',
    styleUrl: './check-address-signup.component.scss'
})
export class CheckAddressSignupComponent implements OnInit {
  private localStorageService = inject(LocalStorageService)
  private zone = inject(NgZone);
  private addressService = inject(AddressService);
  private signalsStoreService = inject(SignalsStoreService);
  private accountService = inject(AccountService);

  @Input() firstStep!: MatStep;
  @Output() onNextStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() deliveryAddressChanged = new EventEmitter<WritableSignal<Partial<DeliveryAddress>>>();
  @Output() selectedDayChanged = new EventEmitter<any>();
  @Output() selectedTimeWindowChanged = new EventEmitter<any>();
  @Output() deliveryOptionChanged = new EventEmitter<any>();

  deliveryAddress: WritableSignal<Partial<DeliveryAddress>> = signal({
    additionalAddress: '',
    address: '',
    city: '',
    deliveryNotes: '',
    stateCode: '',
    zipCode: '',
  })

  deliveryOption: any[] = [];
  deliveryOptionSelected: WritableSignal<any> = signal(null);

  validSelectedAddress = signal<any>(null);

  //#region Pickup Flow
  pickUpOptions: PickUpOption[] = [];
  selectedPickUpOption: WritableSignal<PickUpOption | null> = signal(null);
  pickUpDeliveryDays: DeliveryDay[] = [];
  selectedPickUpDeliveryDay: WritableSignal<any> = signal(null);
  zoneId: number = 0;
  //#endregion

  //#region Check Address Flow
  selectedDeliveryDay: WritableSignal<DeliveryOptionsResponseItem | null> = signal(null);
  checkAddressDeliveryDays!: DeliveryOptionsResponseItem;
  timeSet: Signal<ArrayOptionsItem[]> = computed(() => this.setUpTimeSet());
  selectedDeliveryTimeWindow: any = signal(null);
  //#endregion

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.updateDeliverySelection();
    this.getAllDeliveryOptions();
    this.addressService.setFirstPickUpMembershipSelected$.pipe(tap((value) => value ? this.selectFirstPickUpMembership() : null)).subscribe();
  }

  private getAllDeliveryOptions() {
    this.accountService.getAllCustomerTypes().subscribe(deliveryOption => {
      this.deliveryOption = deliveryOption.data;
      const storedDeliveryOptionSelected: any = this.localStorageService.get(LOCALSTORAGE_KEYS.SELECTED_DELIVERY_PREFERENCE);
      const signupSelectedMembership: any = this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_SELECTED_MEMBERSHIP);
      const selectedDeliveryOption = this.deliveryOption.find(d => d.id === storedDeliveryOptionSelected?.id || d.id === signupSelectedMembership?.id) || this.deliveryOption[0];
      this.deliveryOptionSelected.set(selectedDeliveryOption);
      if (!this.deliveryOptionSelected()?.configuration?.requireAddress)
        this.getPickupOptions(this.deliveryOptionSelected())
    });
  }

  getPickupOptions(membership: any) {
    if (!membership?.id) return;
    this.accountService.getPickUpOption(membership.id).subscribe(pickUpOptions => {
      this.pickUpOptions = pickUpOptions.map((l: { composedName: string; name: any; locationAddress: any; }) => {
        l.composedName = `${(l.name ? `${l.name} - ` : '') || ''}${l.locationAddress}`;
        return l;
      });
      const storedPickUpLocation: any = this.localStorageService.get(LOCALSTORAGE_KEYS.SELECTED_PICKUP_OPTION);
      if (storedPickUpLocation)
        this.setSelectedPickUpOption({ value: storedPickUpLocation.id })

      if (this.pickUpOptions.length === 1)
        this.setSelectedPickUpOption({ value: this.pickUpOptions[0].id });
    });
  }

  private getDeliveryOptions() {
    this.accountService
      .getDeliveryOptions(this.addressService.navegoAddress()?.zoneId)
      .subscribe({
        next: (options: DeliveryOptionsResponseItem[]) => {
          if (options && options.length) {
            options.forEach(x => x.formattedName = this.#formattDeliveryDay(x.deliveryDate ?? ''))
            this.checkAddressDeliveryDays = options[0];
            this.selectedDeliveryDay.set(options[0]);
          }
        }
      });
  }

  #formattDeliveryDay(date: string): string {
    const { dayName, day, month } = formatDateToReadableString(date);
    return `${dayName}, ${month} ${day}`;
  }

  updateDeliveryAddress(newValue: any) {
    this.deliveryAddress.update((value) => ({ ...value, ...newValue }))
  }

  setSelectedPickUpOption(event: any) {
    this.selectedPickUpDeliveryDay.set(null)
    const option = this.pickUpOptions.find(o => o.id === event.value);
    if (option) {
      this.selectedPickUpOption.set(option);
      this.zoneId = option.id
      this.pickUpDeliveryDays = option.deliveryDays;
    }
  }

  setSelectedPickUpDeliveryDays(event: MatSelectChange) {
    const option = this.pickUpDeliveryDays.find(o => o.id === event.value);
    if (option) {
      this.selectedPickUpDeliveryDay.set({ ...option, deliveryDay: option.id, zoneId: this.zoneId });
    }
  }

  public updateDeliverySelection(restart: boolean = false, loadPickups: boolean = false) {
    if (restart) {
      this.validSelectedAddress.set(null);
    }
    this.updateDeliveryAddress({ 'deliveryOptionSelected': this.deliveryOptionSelected() });
    this.selectedPickUpDeliveryDay.set(null);
  }

  private setUpTimeSet() {
    const selectedDay = this.selectedDeliveryDay();
    return (selectedDay && selectedDay.timeSet && selectedDay.timeSet.length) ? [{ id: -1, name: "No preferences" }, ...selectedDay.timeSet] : [];
  }

  shouldDisabledPickUpFlow() {
    return !this.selectedPickUpDeliveryDay() || !this.selectedPickUpOption()
  }

  shouldDisableCheckAddresFlow() {
    return !this.selectedDeliveryDay() || !this.deliveryOptionSelected() || !this.validSelectedAddress() || this.deliveryAddress().deliveryNotes == '' || (this.deliveryAddress().zipCode?.length ?? 0) < 5
  }

  continue() {

    if (this.shouldDisableCheckAddresFlow())
      return;

    this.firstStep.completed = true
    this.deliveryAddressChanged.emit(this.deliveryAddress);

    if (this.deliveryOptionSelected()?.configuration?.requireAddress) {
      this.selectedDayChanged.emit(this.selectedDeliveryDay());
      this.selectedTimeWindowChanged.emit(this.selectedDeliveryTimeWindow())
    } else {
      this.selectedDayChanged.emit(this.selectedPickUpDeliveryDay());
      this.selectedTimeWindowChanged.emit(-1); // Pickup locations doesn't requires time window for ME
    }

    this.deliveryOptionChanged.emit(this.deliveryOptionSelected());
    this.localStorageService.set(LOCALSTORAGE_KEYS.SIGNUP_SELECTED_MEMBERSHIP, this.deliveryOptionSelected());
    this.onNextStep.emit(this.firstStep);
  }

  selectFirstPickUpMembership() {
    const selected = this.deliveryOption.find(m => !m.configuration?.requireAddress);
    this.deliveryOptionSelected.set(selected);
    this.getPickupOptions(this.deliveryOptionSelected())
  }

  onValidDeliveryAddressSelected(e: any) {
    this.validSelectedAddress.set(e);
    if (!!this.addressService.navegoAddress()) this.getDeliveryOptions();
    this.updateDeliveryAddress({ zipCode: e?.zipCode ?? '' })
  }

}
