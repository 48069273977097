@if (deliveryOption.length > 1) {
<!-- Delivery type selection -->
<p class="signup__sub-head-text">What kind of delivery are you interested in?</p>

<mat-radio-group id="mat-radio-group" class="row" [(ngModel)]="deliveryOptionSelected"
  (ngModelChange)="getPickupOptions($event)" (click)="updateDeliverySelection(true)">

  @for (delivery of deliveryOption; track $index) {
  <div class="col-sm-4">
    <mat-radio-button [checked]="delivery.id === deliveryOptionSelected()?.id" [value]="delivery">
      {{delivery.name}}
    </mat-radio-button>
  </div>
  }

</mat-radio-group>
}

<!-- Delivery type which requires pickup options configuration: -->
@if(!deliveryOptionSelected()?.configuration?.requireAddress){

<!-- PickUp options: if there are most than one option, should load as a dropdown, otherwise as a readonly input  -->
@if (pickUpOptions.length > 1) {
<!-- Pickup options: dropdown -->
<div class="animated fadeInDown my-5">
  <mat-form-field class="w-100" [class.option-selected]="!!selectedPickUpOption()">
    <mat-label>Pickup Address</mat-label>
    <mat-select name="selectedPickUpOption" [value]="selectedPickUpOption()?.id || null"
      (selectionChange)="setSelectedPickUpOption($event)">
      @for (item of pickUpOptions; track $index) {
      <mat-option [value]="item.id">{{ item.composedName }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
} @else {
<!-- Pickup options: readonly input -->
<div class="form-floating my-5">
  <input type="text" class="form-control" id="pickupLocation" name="pickUpLocation" readonly
    [value]="selectedPickUpOption()?.composedName || ''" placeholder>
  <label for="address">Pickup address</label>
</div>
}

<!-- Pickup delivery day options -->
<div class="row form-row animated fadeInDown">
  <div class="col-md-6">
    <h6 class="signup__sub-head-text">When will you be picking up your order</h6>
    <mat-form-field class="w-100" [class.option-selected]="!!selectedPickUpOption()">
      <mat-label>Choose a day</mat-label>
      <mat-select name="selectedDeliveryDay" [value]="selectedPickUpDeliveryDay()?.id || null"
        (selectionChange)="setSelectedPickUpDeliveryDays($event)">
        @for (item of pickUpDeliveryDays; track $index) {
        <mat-option [value]="item.id">{{ item.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <!-- Delivery day time window: -->
  <div class="col-md-6 d-flex align-items-end">
    @if (selectedPickUpDeliveryDay()) {
    <mat-form-field class="w-100 animated fadeInLeft">
      <mat-label>Time window</mat-label>
      <input type="text" matInput readonly="true" name="selectedDeliveryTimeWindow"
        [value]="selectedPickUpDeliveryDay()?.deliveryWindow">
    </mat-form-field>
    }
  </div>
</div>

@if (selectedPickUpOption()?.additionalInfo) {
<div class="alert alert-warning d-flex align-items-center animated fadeIn mt-4 px-5" role="alert">
  <div class="h7-regular">
    <div class="pickup-additional-info" [innerHTML]="selectedPickUpOption()?.additionalInfo"></div>
  </div>
</div>
}

<div class="row form-row-action">
  <div class="col">
    <div class="d-grid gap-2 mt-4 col-md-2 col-12 w-50 mx-auto">
      <button type="button" (click)="continue()" [disabled]="shouldDisabledPickUpFlow()"
        class="btn btn-secondary animated fadeIn head-font">
        Continue
      </button>
    </div>
  </div>
</div>

} @else {
<!-- Delivery option which required check address validation -->
<form class="needs-validation"
  [class.was-validated]="!!validSelectedAddress() && deliveryAddress().deliveryNotes != '' && (this.deliveryAddress().zipCode?.length ?? 0) === 5">
  <div class="row form-row mt-5">
    <div class="col">
      <app-autocomplete-input (validSelectedAddress)="onValidDeliveryAddressSelected($event)"></app-autocomplete-input>
    </div>
  </div>
  @if (!!validSelectedAddress()) {
  <div class="row form-row animated fadeInDown mt-5">
    <div class="col">
      <div class="form-floating">
        <input type="text" class="form-control" name="additionalAddress" maxlength="100"
          [class.is-valid]="(deliveryAddress().additionalAddress?.length ?? 0) > 0"
          [ngModel]="deliveryAddress().additionalAddress"
          (ngModelChange)="updateDeliveryAddress({additionalAddress: $event })"
          placeholder="Type your additional address information" />
        <label for="additionalAddressCtrl">Additional address information</label>
      </div>
    </div>
    <div class="col">
      <div class="form-floating">
        <input type="text" class="form-control" name="zipCode"
          [class.is-invalid]="(this.deliveryAddress().zipCode?.length ?? 0) < 5"
          [class.is-valid]="(this.deliveryAddress().zipCode?.length ?? 0) === 5" [ngModel]="deliveryAddress().zipCode"
          (ngModelChange)="updateDeliveryAddress({zipCode: $event })" placeholder="Type your ZIP Code" required
          minlength="5" />
        <label for="zipCodeCtrl">ZIP Code</label>
        <div class="invalid-tooltip">
          Please provide a valid ZIP Code
        </div>
      </div>
    </div>
  </div>
  }

  @if (validSelectedAddress()) {
  <div class="row form-row animated fadeInDown">
    <div class="col">
      <div class="form-floating">
        <textarea class="form-control"
          placeholder="Please help our drivers find you and get your order to the right place. Consider including details such as: where to leave your box, any building or gate codes, or identifying features for your residence."
          name="deliveryNotes" maxlength="150" [class.is-invalid]="deliveryAddress().deliveryNotes == ''"
          [class.is-valid]="deliveryAddress().deliveryNotes != ''" [ngModel]="deliveryAddress().deliveryNotes"
          (ngModelChange)="updateDeliveryAddress({deliveryNotes: $event})" style="height: 100px">
            </textarea>
        <label for="deliveryNotesCtrl"
          title="Please help our drivers find you and get your order to the right place. Consider including details such as: where to leave your box, any building or gate codes, or identifying features for your residence.">Please
          help our drivers find you and get your order to the right place. Consider including details such as: where to
          leave your box, any building or gate codes, or identifying features for your residence.</label>
      </div>
    </div>
  </div>

  <!-- Check Address Flow: delivery day and time window configuration -->
  @if (checkAddressDeliveryDays && checkAddressDeliveryDays.formattedName) {
  <div class="row form-row animated fadeInDown">
    <!-- Next delivery date -->
    <div class="col-md-12">
      <h6 class="signup__sub-head-text">Your Next Delivery Date Is:</h6>
      <div class="d-flex gap-3 next-delivery-date">
        <span class="material-symbols-outlined icon fs-3">
          local_shipping
        </span>
        <div class="d-flex flex-column">
          <span class="h5-regular">
            {{checkAddressDeliveryDays.formattedName}}
          </span>
          <span class="h6-regular">
            {{checkAddressDeliveryDays.timeSet[0].name}}
          </span>
        </div>
      </div>
    </div>
  </div>
  }

  <div class="row form-row-action ">
    <div class="col">
      <div class="d-grid gap-2 mt-4 col-md-2 col-12 w-50 mx-auto">
        <button type="button" (click)="continue()" [disabled]="shouldDisableCheckAddresFlow()"
          class="btn btn-secondary animated fadeIn head-font">
          Continue
        </button>
      </div>
    </div>
  </div>
  }
</form>
}