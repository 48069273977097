import { Component, inject } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-pending-payment-method-no-signup',
    providers: [provideNativeDateAdapter()],
    imports: [RouterLink],
    template: `
  <div class="container">
    <div class="row">
      <div class="col mb-3 text-center">
        <small>
        Welcome back! We noticed that you don't have a payment method registered on your account. To continue shopping and enjoy a seamless checkout experience, please add a payment method now.
        </small>
      </div>
    </div>

    <div class="row mt-0 mb-2 justify-content-center">
      <div class="col-md-auto">
        <button
          type="button"
          class="btn btn-primary"
          (click)="activeModal.close()"
          [routerLink]="'settings/billing/payment-method'">
          Add Payment Method
        </button>
      </div>
    </div>
  </div>
`
})
export class PendingPaymentMethodNoSignupComponent {
  activeModal = inject(NgbActiveModal);
}
