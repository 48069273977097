import { AfterViewInit, Component, ElementRef, Inject, InputSignal, NgZone, PLATFORM_ID, Signal, ViewChild, computed, inject, input, output, signal, } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { GoogleMapsUtils } from '../../../shared/common/utils';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../../environments/environment.development';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { isPlatformBrowser } from '@angular/common';
import { AddressService, NavegoAddressRequest } from '../../../shared/address.service';
import { DeliveryAddress } from '../signup.types';
import { toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Component({
    selector: 'app-autocomplete-input',
    imports: [FormsModule, MatProgressBarModule],
    providers: [
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: environment.apis.google.places,
                libraries: ['places'],
                region: 'US',
            }),
        },
    ],
    templateUrl: './autocomplete-input.component.html',
    styleUrl: './autocomplete-input.component.scss'
})
export class AutocompleteInputComponent implements AfterViewInit {
  @ViewChild('autocompleteInput', { static: false })
  autocompleteInput!: ElementRef<HTMLInputElement>;

  label: InputSignal<string> = input('Address');

  private zone = inject(NgZone);
  private addressService = inject(AddressService);

  validSelectedAddress = output<any>();

  hasDeliveryAddressCoverage: Signal<boolean> = computed(() => !!this.addressService.navegoAddress());
  hasDeliveryAddressCoverage$ = toObservable(this.hasDeliveryAddressCoverage).pipe(
    tap((hasCoverage) => {
      this.validSelectedAddress.emit(hasCoverage ? this.deliveryAddress : null)
      this.controlHasError.set(!hasCoverage && this.controlWasTouched());
    })
  )

  isGoogleAddressFilled = signal(false);
  controlHasError = signal(false);
  controlWasTouched = signal(false);
  isLoadingContent: any = computed(() => this.addressService.isLoading());

  anonymousUserAddress: any = computed(() =>
    this.signalsStoreService.anonymousUserAddress()
  );

  localStorageService = inject(LocalStorageService);
  signalsStoreService = inject(SignalsStoreService);
  loader = inject(Loader);
  queryString: string = '';

  googleAddress: any = this.signalsStoreService.googleAddress;

  deliveryAddress: Partial<DeliveryAddress> = {
    additionalAddress: '',
    address: '',
    city: '',
    deliveryNotes: '',
    state: '',
    zipCode: undefined,
  }

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    const locationText: string | null =
      this.signalsStoreService.anonymousUserAddress()?.street ?? '';
    if (locationText) this.queryString = locationText;

    setTimeout(() => {
      this.addressService.navegoAddress.set(undefined);

      this.hasDeliveryAddressCoverage$.subscribe();

      GoogleMapsUtils.initializeAutocomplete(
        this.loader,
        this.autocompleteInput.nativeElement,
        this.onPlaceSelected.bind(this),
        this.localStorageService
      );
      GoogleMapsUtils.loadAutoCompleteIfAny(
        this.loader,
        this.onPlaceSelected.bind(this),
        this.localStorageService
      );
    }, 500);
  }

  onPlaceSelected(place: google.maps.places.PlaceResult) {
    this.zone.run(() => {
      const {
        city,
        stateCode,
        zipCode,
        name: street,
        latitude,
        longitude,
        formattedAddress,
        placeId
      } = this.addressService.setupDeliveryData(place);

      this.googleAddress.set({
        street,
        city,
        stateCode,
        zipCode,
        latitude,
        longitude,
        placeId,
      });

      this.deliveryAddress = { ...this.deliveryAddress, ...this.googleAddress() };
      this.autocompleteInput.nativeElement.value = formattedAddress ?? '';
      this.isGoogleAddressFilled.set(true);
      this.checkDeliveryAddressCoverage();
    });

  }

  checkDeliveryAddressCoverage() {
    const { street, city, stateCode, latitude, longitude } = this.googleAddress();
    const navegoAddressRequest = {
      street,
      city,
      state: stateCode,
      latitude,
      longitude,
    }

    this.addressService.checkDeliveryAddress(navegoAddressRequest as NavegoAddressRequest, true, false);
  }

  changeAddress(e: any) {
    this.addressService.navegoAddress.set(undefined);
    this.isGoogleAddressFilled.set(false);
    this.controlHasError.set(true);
  }

  onFocusOut(e: any) {
    this.controlWasTouched.set(true);
    this.controlHasError.set(!this.hasDeliveryAddressCoverage());
  }
}
