import { Component, Input, computed, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { ModalContentData } from '../modal-content';
import { SignalsStoreService } from '../../signals-store.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-welcome-message',
    imports: [RouterLink],
    templateUrl: './welcome-message.component.html',
    styleUrl: './welcome-message.component.scss'
})
export class WelcomeMessageComponent {

  private signalsStoreService = inject(SignalsStoreService);

  isLimitedUser = computed(() => this.signalsStoreService.isLimitedUser());

  activeModal = inject(NgbActiveModal);
  router = inject(Router);

  @Input() modalContentData!: ModalContentData;

  goTo() {
    let route = 'shop';
    if (this.isLimitedUser()) {
      // Get the default view to redirect:
      const defaultRoute = environment.config.flows.specialMembership.defaultRoute;
      route = defaultRoute || route;
    }
    this.router.navigate([`/${route}`]);
    this.close()
  }

  close() {
    this.activeModal.close();
  }
}
