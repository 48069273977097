import { BundleEditionTypes } from "../app/shared/types/order.type";
import { BundleModifyTypes, PurchaseTypes } from "../app/shared/types/flows-config.types";

const base_api = "https://producebox.navego.tech";

// Environment Setup
export const environment = {
  production: true,
  apis: {
    v1: `${base_api}`,
    v2: `${base_api}/api/v2`,
    v3: `${base_api}/api/v3`,
    google: {
      places: 'AIzaSyBTHTviZ7QPtEjH29Jmmkp13RO8LPrxppM'
    },
    navego: {
      url: 'https://apis.navego.tech/api',
      key: 'dsfgrdf125fdffasd677rrfgr',
      clientNr: '11'
    },
    firebase: {
      apiKey: "AIzaSyBduz1iwNtb_fIMw5Zy4UShjlqOIsA9_qo",
      authDomain: "pp-pb-6ff26.firebaseapp.com",
      projectId: "pp-pb-6ff26",
      storageBucket: "pp-pb-6ff26.firebasestorage.app",
      messagingSenderId: "972148648772",
      appId: "1:972148648772:web:f065cd721b27a21c524511",
      measurementId: "G-W7XQ8C69JV"
    },
    klaviyo: {
      publicApiKey: 'TcSwtv'
    },
    maintenance: {
      url: 'https://ecommerce-configurations.navego.tech/api/v3/maintenance?url=producebox'
    },
    authorize: {
      APILoginID: '7QfPv68Y',
      PublicClientkey: '7Lm7CcBssrTDLe8dgNRwhrZ7eRz6cbQBQKGVM9B5ZBDs5yCP582LmU52T9Dtv5Uw'
    }
  },
  application: {
    angular: 'Angular 17.3.7',
    bootstrap: 'Bootstrap 5.3.2',
    fontawesome: 'Font Awesome 6.5.1',
    name: 'The Produce Box',
    version: '1.0.0',
  },
  config: {
    bodyClass: 'app-me',
    crypto: {
      key: '!!!3nCrypt3d???',
    },
    flows: {
      territories: false,
      membership: false,
      settings: {
        toHide: {
          membership: 'Membership'
        },
        profile: {
          hasPickupLocationsFlow: true
        },
        deliveryDayUpdates: {
          displayOnlyActiveReescheduledOrder: true
        }
      },
      specialMembership: {
        defaultRoute: '/order/custom-box/' + BundleEditionTypes.subscription,
        settings: {
          hideHeaderCategoriesNav: true
        },
      },
      order: {
        askForDonationOnSkip: false,
        showFreeDeliverySlider: true,
        voluntaryDonationFlow: false,
      },
      bundles: {
        modifyType: BundleModifyTypes.outOfOrder
      },
      shop: {
        validateMarketState: true
      },
      login: {
        tempResetPassword: true
      },
      purchase: {
        type: PurchaseTypes.anet
      }
    },
    filesVersionNumber: {
      logo: 2
    },
    friendReferral: {
      baseUrl: 'https://theproducebox.com/signup?utm_source=friend_referral&utm_medium=share&utm_campaign='
    },
    seo: {
      mainTitle: 'The Produce Box Nourishing communities, cultivating connection | The Produce Box',
      mainDescription: 'Discover The Produce Box: Committed to sustainable local and regional food. Bringing farmers market freshness to your doorstep with home delivery convenience',
      baseTitle: 'The Produce Box: Sustainable Local Delights Delivered Year-Round',
      baseKeywords: ['Sustainable', 'Local', 'Regional', 'Home Delivery', 'Fresh Produce', 'Online Ordering', 'The Produce Box', 'Food'],
      type: 'website',
      baseURL: 'https://www.theproducebox.com/'
    },
    contactEmail: 'info@theproducebox.com'
  },
};
