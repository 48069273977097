import { Component, OnInit, inject, signal } from '@angular/core';
import { LOCALSTORAGE_KEYS } from '../../constants/databases';
import { LocalStorageService } from '../../local-storage.service';
import { Session } from '../../types/session.type';
import { formatDateToReadableString } from '../../utils/formatting';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-skip-delivery',
    imports: [],
    templateUrl: './skip-delivery.component.html',
    styleUrl: './skip-delivery.component.scss'
})
export class SkipDeliveryComponent implements OnInit {

  private localStorageService = inject(LocalStorageService);
  private activeModal = inject(NgbActiveModal);

  deliveryDate = signal('');

  ngOnInit(): void {
    this.setUpDeliveryDate()
  }

  private setUpDeliveryDate() {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (session)
      this.deliveryDate.set(formatDateToReadableString(session.deliveryInfo?.order?.deliveryDate || '').readableString);
  }

  confirmModalButtonsAction(skip: boolean, goToPause: boolean = false) {
    this.activeModal.close({ skip, goToPause });
  }

}
