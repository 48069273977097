<div class="row mt-5">
    <div class="col-lg-6 col-md-12">
        <mat-calendar
        class="calendar"
        [dateFilter]="dateFilter"
        [minDate]="minDate()"
        #calendar
        (selectedChange)="select($event,calendar)"
        [dateClass]="isSelected">
        </mat-calendar>
    </div>
    <div class="col-lg-6 col-md-12 pt-4">
        <h6>Selected delivery dates:</h6>
        @if (daysSelected.length) {
        <mat-chip-set #chipGrid class="chip-grid">
            @for (date of daysSelected; track $index) {
            <mat-chip
                (removed)="remove($index, calendar, $index === 0 || $index === (daysSelected.length - 1))"
                class="">
                <div class="d-flex gap-1">
                    <span class="">{{date}}</span>
                </div>
                <button matChipRemove [attr.aria-label]="'remove ' + date">
                    @if($index === 0 || $index === (daysSelected.length - 1)) {
                    <mat-icon>close</mat-icon>
                    }
                    </button>
            </mat-chip>
            }
        </mat-chip-set>
        } @else {
        <div class="empty-container">
            <h4>No date has been selected</h4>
        </div>
        }
    </div>
</div>

<div class="row mt-5 animated fadeIn gap-2">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <button type="button" class="btn w-100 btn-outline-primary" (click)="activeModal.close()">Cancel</button>
    </div>
    <div class="col">
        <button type="button" class="btn w-100 btn-primary" (click)="validatePauseDate()">Pause subscriptions</button>
    </div>
</div>
