import { Component, inject } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { SignalsStoreService } from '../../signals-store.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-pending-payment-method',
    providers: [provideNativeDateAdapter()],
    imports: [],
    template: `
    <div class="container">
      <div class="row">
        <div class="col mb-3 text-center">
          <small>
          It appears that you have already started the sign-up process, but have not
          finished it yet. Don't worry, we've saved your progress! To continue right where
          you left off, please complete the remaining steps of the sign-up process below.
          </small>
        </div>
      </div>

      <div class="row mt-0 mb-2 justify-content-center">
        <div class="col-md-auto">
          <button
            type="button"
            class="btn btn-primary"
            (click)="reloadPage('/signup')"
          >
            CONTINUE SIGN UP
          </button>
        </div>
      </div>
    </div>
  `
})
export class PendingPaymentMethodComponent {
  private signalsStoreService = inject(SignalsStoreService)
  private location = inject(Location);

  reloadPage(route: string) {
    // Setting step 3 
    this.signalsStoreService.signUpStep.set(2)
    this.location.replaceState(route);
    window.location.reload();
  }
}
